import axios from 'axios';

const DADATA_TOKEN = process.env.VUE_APP_DADATA_TOKEN
const DADATA_SECRET = process.env.VUE_APP_DADATA_SECRET
const DADATA_ADDRESS_URL = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address"

export const getRegionForSearch = (region) => {
    const entries = [" обл.", " обл"]

    for (let entry of entries) {
        region = region.replaceAll(entry, '');
    }
    return region.trim();
}

export const dadataSearchAddress = async (search, locations = [], restrict_value = false) => {
    const isRestrict = locations || restrict_value;
    await axios.post(
        DADATA_ADDRESS_URL,
        {
            query: search,
            locations,
            restrict_value: isRestrict,
        },
        {
            headers: {
                "Authorization": DADATA_TOKEN,
            },
        }
    ).then(res => {
        return res.data.suggestions.map(item => {
            return {
                label: item.value,
                fias: item.data.fias_id,
                data: item.data,
            }
        });
    }).catch(err => console.warn(err))
}

export const dadataSearchByBound = (search, from_bound = "region", to_bound = "region") => {
    return axios.post(
        DADATA_ADDRESS_URL,
        {
            query: search,
            from_bound: {value: from_bound},
            to_bound: {value: to_bound},
        },
        {
            headers: {
                "Authorization": DADATA_TOKEN,
            },
        }
    ).then(res => {
        const addresses = res.data.suggestions.map(item => {
            return {
                label: item.value,
                fias: item.data.fias_id,
                data: item.data,
            }
        });
        return addresses;
    }).catch(err => console.warn(err))
}

export const dadataSearchByParentAndBound = (search, from_bound = "region", to_bound = "region", locations = []) => {
    return axios.post(
        DADATA_ADDRESS_URL,
        {
            locations,
            query: search,
            restrict_value: true,
            from_bound: {value: from_bound},
            to_bound: {value: to_bound},
        },
        {
            headers: {
                "Authorization": DADATA_TOKEN,
            },
        }
    ).then(res => {
        const addresses = res.data.suggestions.map(item => {
            return {
                label: item.value,
                fias: item.data.fias_id,
                data: item.data,
            }
        });
        return addresses;
    }).catch(err => console.warn(err))
}
