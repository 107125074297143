import $ from "jquery";
import "../vendor/jquery.kladr.js";
import {dadataSearchAddress, dadataSearchByBound, dadataSearchByParentAndBound} from '@/helpers/dadata.js';
import { debounce } from "@/vendor/debounce";

export default class Kladr {
  constructor(config, setValue, setOptions, setFieldNotRequired, setFieldRequired) {
    this.$region = $(`#${config.region}`);
    this.$city = $(`#${config.city}`);
    this.$address = $(`#${config.address}`);
    this.$zip = $(`#${config.zip}`);
    this.config = config;
    this.setValue = setValue;
    this.setOptions = setOptions;
    this.setFieldNotRequired = setFieldNotRequired;
    this.setFieldRequired = setFieldRequired;

    this.MIN_INPUT_LENGTH = 2;
    this.INPUTS_DEBOUNCE_MS = 500;

    this.regionId = undefined;
    this.cityId = undefined;
    this.settlementId = undefined;

    this.selectedCityData = undefined;
    this.selectedAddressData = undefined;

    this.elements = [
      this.$region,
      this.$city,
      this.$address,
      this.$zip,
    ];

    this.restrictedRegions = [
      "0c5b2444-70a0-4932-980c-b4dc0d3f02b5", // Москва
      "c2deb16a-0330-4f05-821f-1d09c93331e6", // Питер
      "6fdecb78-893a-4e3f-a5ba-aa062459463b", // Севастополь
      "63ed1a35-4be6-4564-a1ec-0c51f7383314", // Байконур
    ];
  }

  fieldIsEmpty($field){
    return !$field.val() || !$field.val().trim().length;
  }

  eraseRegion(){
    this.regionId = undefined;
    this.setOptions({id: this.$region.attr('id'), options: []});
    this.setValue({ value: '', id: this.$region.attr('id') });
  }

  eraseCity(){
    this.cityId = undefined;
    this.settlementId = undefined;
    this.setOptions({id: this.$city.attr('id'), options: []});
    this.setValue({ value: '', id: this.$city.attr('id') });
  }

  eraseAddress(){
    this.selectedAddressData = undefined;
    this.setOptions({id: this.$address.attr('id'), options: []});
    this.setValue({ value: '', id: this.$address.attr('id') });

    //индекс как бы тоже...
    this.setOptions({id: this.$zip.attr('id'), options: []});
    this.setValue({ value: '', id: this.$zip.attr('id') });
  }

  fillRegion(regionData){
      this.regionId = regionData.fias;
      this.setOptions({id: this.$region.attr('id'), options: [regionData.name]});
      this.setValue({ value: regionData.name, id: this.$region.attr('id') });

      this.selectedCityData = regionData;
  }

  fillCity(cityData){
    if(cityData.type === 'city'){
      this.cityId = cityData.fias;
      this.settlementId = undefined;
    }
    else{
      this.settlementId = cityData.fias;
      this.cityId = undefined;
    }

    this.selectedAddressData = cityData;
    
    this.setOptions({id: this.$city.attr('id'), options: [cityData.name]});
    this.setValue({ value: cityData.name, id: this.$city.attr('id') });
  }

  fillRegionIfEmpty(regionData){
    if(this.fieldIsEmpty(this.$region)){
      this.fillRegion(regionData);
    }
  }

  makeRegionFieldNotRequired(){
    this.setFieldNotRequired({id: this.$region.attr('id')})
  }

  makeRegionFieldRequired(){
    this.setFieldRequired({id: this.$region.attr('id')})
  }

  inputChangeHandler(context, event, searchBoundFrom = "region", searchBoundTo = "region", mapOptionsValue = undefined){
    const value = event.target.value;
    const id = event.target.id;
    if(value.length >= context.MIN_INPUT_LENGTH){
      if(context.regionId && id === context.config.city || 
        (context.cityId || context.settlementId) && id === context.config.address
      ) {

        let locations = [];
        if(id === context.config.city){
          const regionLocation =  {
            "region_fias_id": context.regionId,
          };
          locations.push(regionLocation);
        }
        if(id === context.config.address){
          if(context.settlementId){
            const settlementLocation =  {
              "settlement_fias_id": context.settlementId,
            };
            locations.push(settlementLocation);
          }
          if(context.cityId){
            const cityLocation =  {
              "city_fias_id": context.cityId,
            };
            locations.push(cityLocation);
          }
        }

        dadataSearchByParentAndBound(value, searchBoundFrom, searchBoundTo, locations).then(options => {
          // console.log(options);
          if(mapOptionsValue){
            context.setOptions({id, options: options.map(mapOptionsValue)});
          }
          else{
            context.setOptions({id, options});
          }
        });
      }
      else{
        dadataSearchByBound(value, searchBoundFrom, searchBoundTo).then(options => {
          // console.log(options);
          if(mapOptionsValue){
            context.setOptions({id, options: options.map(mapOptionsValue)});
          }
          else{
            context.setOptions({id, options});
          }
        });
      }
    }
    else{
      context.setOptions({id, options: []});

      if(id === context.config.city){
        context.cityId = undefined;
        context.selectedCityData = undefined;
      }

      if(id === context.config.region){
        context.regionId = undefined;
      }
    }
  }

  regionInit() {
    const that = this;
    this.$region.on("input", debounce(function(event){
      that.inputChangeHandler(that, event);
    }, this.INPUTS_DEBOUNCE_MS));

    this.$region.on("choose-option", function(event){
      that.regionId = event.detail.fias;

      if(that.selectedCityData && that.selectedCityData.fias !== event.detail.fias){
        that.eraseCity();
      }
      if(that.selectedAddressData && that.selectedAddressData.fias !== event.detail.fias){
        that.eraseAddress();
      }
    });
  }

  cityInit(region = "5000000000000") {
    const that = this;
    this.$city.on("input", debounce(function(event){
      that.inputChangeHandler(that, event, "city", "settlement", (item, index) => {
        let label = item.value;
        if(!label){
          label = item.label;
        }

        if(that.fieldIsEmpty(that.$region)){

          let labelParts = [];

          if(item.data.area_with_type){
            labelParts.push(item.data.area_with_type);
          }
          if(item.data.city_with_type){
            labelParts.push(item.data.city_with_type);
          }

          if(item.data.settlement_with_type){
            labelParts.push(item.data.settlement_with_type);
          }

          label = labelParts.join(", ");
        }

        let type = 'city';
        if(item.data.settlement_fias_id){
          type = 'settlement';
        }
         
        return {
          label,
          fias: item.fias,
          type,
          region: {
            name: item.data.region_with_type,
            fias: item.data.region_fias_id,
          },
        };
      });
    }, this.INPUTS_DEBOUNCE_MS));

    this.$city.on("choose-option", function(event){
      const entityType =  event.detail.type;
      if(entityType){
        if(entityType == 'city'){
          that.cityId = event.detail.fias;
          that.settlementId = undefined;
        }
        else{
          that.settlementId = event.detail.fias;
          that.cityId = undefined;
        }
      }
      else{
        that.cityId = event.detail.fias;
      }


      if(event.detail.region){
        //заполняем всё, кроме этих городов выше, у них поле Регион должно быть пустое!
        if(!that.restrictedRegions.includes(event.detail.region.fias)){
          that.fillRegion(event.detail.region);

          if(event.detail.label && event.detail.label.includes(event.detail.region.name)){
            const cityNameParts = event.detail.label.split(event.detail.region.name);
            if(Array.isArray(cityNameParts) && cityNameParts.length > 1){
              const cityName = cityNameParts[0].slice(1).trim();
              that.setOptions({id: that.$city.attr('id'), options: [cityName]});
              that.setValue({id: that.$city.attr('id'), value: cityName});
            }
          }
          that.makeRegionFieldRequired();
        }
        else{
          that.eraseRegion();
          that.makeRegionFieldNotRequired();
        }
      }

      if(that.selectedAddressData && that.selectedAddressData.fias !== event.detail.fias){
        that.eraseAddress();
      }

    });

    //если мы загружаем начатую форму и переходим назад - надо смотреть изначальное значение поля
    //города и сразу изменить требования к полям области, иначе невозможно будет
    //пройти далее, пока не выбрать тот же город заново
    const currentStartCityValue = this.$city.val();
    const restrictedRegionCities = ["г Москва", "г Санкт-Петербург", "г Байконур", "г Севастополь"];
    if(restrictedRegionCities.includes(currentStartCityValue)){
      that.makeRegionFieldNotRequired();
    }
    else{
      that.makeRegionFieldRequired();
    }

  }


  addressInit(city = "7700000000000") {

    const that = this;
    this.$address.on("input", debounce(function(event){
      that.inputChangeHandler(that, event, "street", "house", (item, index) => {
        const data = item.data;
        let label = item.value;
        if(!label){
          label = item.label;
        }
         
        return {
          label,
          fias: item.fias,
          zip: item.data.postal_code ? item.data.postal_code : "",
          region: {
            name: item.data.region_with_type,
            fias: item.data.region_fias_id,
          },
          cityOrSettlement: {
            name: item.data.city_with_type ? item.data.city_with_type : item.data.settlement_with_type,
            fias: item.data.city_fias_id ? item.data.city_fias_id : item.data.settlement_fias_id,
            type: item.data.city_with_type ? 'city' : 'settlement',
          },
        };
      });
    }, this.INPUTS_DEBOUNCE_MS));

    this.$address.on("choose-option", function(event){
      that.setValue({ value: event.detail.zip, id: that.$zip.attr('id') });
      const zipEvent = new Event('zip.updated');
      document.dispatchEvent(zipEvent);

      if(event.detail.region){
        if(!that.restrictedRegions.includes(event.detail.region.fias)){
          that.fillRegion(event.detail.region);
          that.makeRegionFieldRequired();
        }
        else{
          that.makeRegionFieldNotRequired();
        }
      }

      if(event.detail.cityOrSettlement){
        that.fillCity(event.detail.cityOrSettlement);
        const streetParts = event.detail.label.split(event.detail.cityOrSettlement.name);
        if(streetParts.length > 1){
          const streetName = streetParts[1].slice(1).trim();
          that.setOptions({options: [streetName], id: that.$address.attr('id')})
          that.setValue({ value: streetName, id: that.$address.attr('id') });
        }
      }

    });

  }

  zipInit(){
    const that = this;
    this.$zip.on("input", debounce(function(event){

      const value = event.target.value;

      if(value.length >= 6){
        that.inputChangeHandler(that, event, "region", "house", (item, index) => {
          const data = item.data;
          let label = item.value;
          if(!label){
            label = item.label;
          }
           
          return {
            label,
            fias: item.fias,
            zip: item.data.postal_code ? item.data.postal_code : "",
            region: {
              name: item.data.region_with_type,
              fias: item.data.region_fias_id,
            },
            cityOrSettlement: {
              name: item.data.city_with_type ? item.data.city_with_type : item.data.settlement_with_type,
              fias: item.data.city_fias_id ? item.data.city_fias_id : item.data.settlement_fias_id,
              type: item.data.city_with_type ? 'city' : 'settlement',
            },
          };
        });
      }

    }, this.INPUTS_DEBOUNCE_MS*2));

    this.$zip.on("choose-option", function(event){
      const zipEvent = new Event('zip.updated');
      document.dispatchEvent(zipEvent);

      if(event.detail.region){
        if(!that.restrictedRegions.includes(event.detail.region.fias)){
          that.fillRegion(event.detail.region);
        }
      }

      if(event.detail.cityOrSettlement){
        that.fillCity(event.detail.cityOrSettlement);
        const streetParts = event.detail.label.split(event.detail.cityOrSettlement.name);
        if(streetParts.length > 1){
          const streetName = streetParts[1].slice(1).trim();
          that.setOptions({options: [streetName], id: that.$address.attr('id')})
          that.setValue({ value: streetName, id: that.$address.attr('id') });
        }
      }

      that.setValue({ value: event.detail.zip, id: that.$zip.attr('id') });
      that.setOptions({ options: [], id: that.$zip.attr('id') });
    });
  }


  init() {
    this.regionInit();
    this.cityInit();
    this.addressInit();

    // пока убираем поиск по полю индекса, т.к. местами он ведет себя неадекватно
    // this.zipInit();

    for (const selector in this.config) {
      const element = $("#" + this.config[selector]);

      if (element) {
        element.focusout(() => {
          this.setValue({ value: element.val(), id: element.attr("id") });
        });
      }
    }
  }
}
